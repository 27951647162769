<script>
const decimalFormat = new Intl.NumberFormat('th-TH', { style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 5 })
</script>
<script setup>
import {computed, ref, reactive, onMounted} from "vue";
import {useToast} from "primevue/usetoast";
import {required} from "@vuelidate/validators";
import {useVuelidate} from "@vuelidate/core";
import {storeToRefs} from "pinia";
import {CalculatorStore} from "@/uhas/store/calculator";

const toast = useToast();
const calculatorStore = CalculatorStore();

/**
 * Data
 */
const blockOverlay = ref(false);

const {configs: calculatorConfigs} = storeToRefs(calculatorStore);

const orderType = ref("Buy");
const basicInfoState = reactive({
	portInvest: null,
	currentPrice: null,
	profitPrice: null,
	lossPrice: null,
	forexContractSize: null,
	forexLeverage: null,
});
const basicInfoValidation = useVuelidate({
	portInvest: {
		required,
		gtZero: v => v > 0
	},
	currentPrice: {
		required,
		min: v => (orderType.value === "Buy" && v > (basicInfoState.lossPrice || 0)) || (orderType.value === "Sell" && v > (basicInfoState.profitPrice || 0)),
		max: v => (orderType.value === "Buy" && v < (basicInfoState.profitPrice || Number.MAX_VALUE)) || (orderType.value === "Sell" && v < (basicInfoState.lossPrice || Number.MAX_VALUE))
	},
	profitPrice: {
		required,
		minmax: v => (orderType.value === "Buy" && v > (basicInfoState.currentPrice || 0)) || (orderType.value === "Sell" && v < (basicInfoState.currentPrice || Number.MAX_VALUE)),
	},
	lossPrice: {
		required,
		minmax: v => (orderType.value === "Buy" && v < (basicInfoState.currentPrice || Number.MAX_VALUE)) || (orderType.value === "Sell" && v > (basicInfoState.currentPrice || 0)),
	},
	forexContractSize: { required },
	forexLeverage: { required },
}, basicInfoState);
const basicInfoSubmitted = ref(false);

const calculatedModal = ref(false);


/**
 * Computed
 */
const forexSymbolsContractSize = computed(() => {
	if (!calculatorConfigs.value?.forexSymbolsContractSize) {
		return [];
	}

	let groupedSymbol = [],
	sortedSymbols = calculatorConfigs.value?.forexSymbolsContractSize,
	prevSymbol = null,
	i = -1;
	sortedSymbols.sort((a, b) => ((a.category > b.category) ? 1 : ((b.category > a.category) ? -1 : 0)));
	for (const symbol of sortedSymbols) {
		if (prevSymbol !== symbol.category) {
			prevSymbol = symbol.category;
			groupedSymbol[++i] = {
				label: symbol.category,
				items: [{...symbol
					// label: symbol.symbol,
					// value: symbol.contract_size
				}]
			};
		} else {
			groupedSymbol[i].items.push({...symbol
				// label: symbol.symbol,
				// value: symbol.contract_size
			});
		}
	}

	return groupedSymbol;
});
const pricePercentIncrease = computed(() => {
	if (!basicInfoState.currentPrice || basicInfoState.currentPrice <= 0) {
		return 0;
	}

	return (basicInfoState.profitPrice - basicInfoState.currentPrice) * 100 / basicInfoState.currentPrice;
});
const pricePercentDecrease = computed(() => {
	if (!basicInfoState.currentPrice || basicInfoState.currentPrice <= 0) {
		return 0;
	}

	return (basicInfoState.lossPrice - basicInfoState.currentPrice) * 100 / basicInfoState.currentPrice;
});
const risks = computed(() => {
	if (basicInfoState.forexContractSize?.category === "Forex") {
		return [20, 5, 50, 100, 150]
	} else if (basicInfoState.forexContractSize?.category === "คริปโตฯ") {
		return [1.75, 1, 5, 10, 15]
	} else if (basicInfoState.forexContractSize?.category === "หุ้น") {
		return [3, 1.5, 8, 15, 30]
	} else if (basicInfoState.forexContractSize?.category === "พลังงาน") {
		return [2, 1, 7.5, 12, 20]
	} else if (basicInfoState.forexContractSize?.category === "โลหะ") {
		return [6, 3, 15, 30, 75]
	}

	return [0, 0, 0, 0, 0];
});
const currentPriceInUSD = computed(() => basicInfoState.currentPrice * (basicInfoState.forexContractSize?.y || 1));
const profitPriceInUSD = computed(() => basicInfoState.profitPrice * (basicInfoState.forexContractSize?.y || 1));
const lossPriceInUSD = computed(() => basicInfoState.lossPrice * (basicInfoState.forexContractSize?.y || 1));
const level0 = computed(() => Math.floor(risks.value[0] * 100 * basicInfoState.portInvest / (currentPriceInUSD.value * basicInfoState.forexContractSize?.contract_size)) / 100);
const level1 = computed(() => Math.floor(risks.value[1] * 100 * basicInfoState.portInvest / (currentPriceInUSD.value * basicInfoState.forexContractSize?.contract_size)) / 100);
const level2 = computed(() => Math.floor(risks.value[2] * 100 * basicInfoState.portInvest / (currentPriceInUSD.value * basicInfoState.forexContractSize?.contract_size)) / 100);
const level3 = computed(() => Math.floor(risks.value[3] * 100 * basicInfoState.portInvest / (currentPriceInUSD.value * basicInfoState.forexContractSize?.contract_size)) / 100);
const level4 = computed(() => Math.floor(risks.value[4] * 100 * basicInfoState.portInvest / (currentPriceInUSD.value * basicInfoState.forexContractSize?.contract_size)) / 100);
const notEnoughCapital = computed(() => {
	const msg = "จำนวนเงินลงทุนของคุณ ไม่เพียงพอสำหรับความเสี่ยง ระดับ ";
	const lots = [
		// {level: 0, lot: level0.value},
		{level: 1, lot: level1.value},
		{level: 2, lot: level2.value},
		{level: 3, lot: level3.value},
		{level: "สูงสุด", lot: level4.value},
	];
	const highRisks = lots.filter(item => item.lot < 0.01);

	return highRisks.length === 0 ? "" : msg + highRisks.map(item => item.level).join(" / ระดับ ");
});
const highRiskLots = computed(() => {
	const msg = "คุณไม่ควรเทรด ด้วยขนาด Lot ขนาดความเสี่ยง ระดับ ";
	const risksLevel = [
		// {level: 0, risk: stopLoss(level0.value) * 100 / basicInfoState.portInvest},
		{level: 1, risk: stopLoss(level1.value) * 100 / basicInfoState.portInvest},
		{level: 2, risk: stopLoss(level2.value) * 100 / basicInfoState.portInvest},
		{level: 3, risk: stopLoss(level3.value) * 100 / basicInfoState.portInvest},
		{level: "สูงสุด", risk: stopLoss(level4.value) * 100 / basicInfoState.portInvest},
	];
	const highRisks = risksLevel.filter(item => item.risk < -8);

	return highRisks.length === 0 ? "" : msg + highRisks.map(item => item.level).join(" / ระดับ ");
});


/**
 * Mounted
 */
onMounted(() => {
	calculatorStore.getConfigs()
		.then(() => {
			// Set default of forexLeverage
			if (calculatorConfigs.value?.forexLeverage) {
				if (calculatorConfigs.value?.forexLeverage.indexOf('500') !== -1) {
					basicInfoState.forexLeverage = '500';
				} else {
					basicInfoState.forexLeverage = calculatorConfigs.value?.forexLeverage[0];
				}
			}
		})
		.catch(() => {
			toast.add({
				severity:"error",
				summary: "Error Message",
				detail: "There is a problem with retrieving calculator config.",
				life: 20000
			});
		})
});


/**
 * Actions
 */
const updateContractSize = () => {
	if (basicInfoState.forexContractSize?.type) {
		const currentPrice = parseFloat(basicInfoState.forexContractSize?.x || 1) / parseFloat(basicInfoState.forexContractSize?.y || 1);
		basicInfoState.currentPrice = currentPrice;
		basicInfoState.profitPrice = currentPrice;
		basicInfoState.lossPrice = currentPrice;
	}
}
const lotsCalculate = () => {
	basicInfoSubmitted.value = true;
	if (basicInfoValidation.value.$invalid) {
		return;
	}

	calculatedModal.value = true;
}
const takeProfit = (lots) => {
	const oldContractValue = lots * basicInfoState.forexContractSize?.contract_size * currentPriceInUSD.value;
	const profitContractValue = lots * basicInfoState.forexContractSize?.contract_size * profitPriceInUSD.value;

	return (profitContractValue - oldContractValue) * (orderType.value === "Buy" ? 1 : -1);
}
const stopLoss = (lots) => {
	const oldContractValue = lots * basicInfoState.forexContractSize?.contract_size * currentPriceInUSD.value;
	const lossContractValue = lots * basicInfoState.forexContractSize?.contract_size * lossPriceInUSD.value;

	return (lossContractValue - oldContractValue) * (orderType.value === "Buy" ? 1 : -1);
}
const numberFormat = v => decimalFormat.format(v);
</script>

<style lang="scss">
.block-overlay {
	box-shadow: unset;

	.p-dialog-content {
		background: transparent;
    overflow: hidden;

		circle {
			fill: none;
		}
	}
}
</style>
<style lang="scss" scoped>
::v-deep input {
	&::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
		color: #bbb;
		opacity: 1; /* Firefox */
	}
	&:-ms-input-placeholder { /* Internet Explorer 10-11 */
		color: #bbb;
	}
	&::-ms-input-placeholder { /* Microsoft Edge */
		color: #bbb;
	}
}

::v-deep .p-card-body {
	padding: 2rem;
}
::v-deep.advice-card .p-card-body {
	padding: 1rem 1rem 0 1rem;
}
</style>

<template>
	<Toast />
	<ConfirmPopup />
	<Dialog
		v-model:visible="blockOverlay"
		class="block-overlay"
		appendTo="self"
		:closeOnEscape="false"
		:showHeader="false"
		modal
	>
		<ProgressSpinner
			style="width:5vw;height:5vw"
			strokeWidth="8"
			fill="var(--surface-ground)"
			animationDuration=".5s"
		/>
	</Dialog>

	<Card>
		<template #title>
      การวางแผน การออก Lot
    </template>
		<template #content>
			<!-- <div class="font-semibold text-xl">
				ภาพรวมพอร์ตการเทรดตอนนี้
			</div> -->
			<Divider
				align="left"
				type="dashed"
				class="text-lg mt-5 mb-4"
			>
				<b>ภาพรวมพอร์ตการเทรดตอนนี้</b>
			</Divider>

			<div class="w-full md:w-25rem mt-5">
				<div class="flex justify-content-between mt-1">
					<span class="align-self-center">
						ความต้องการ
					</span>
					<SelectButton
						v-model="orderType"
						:options="['Buy', 'Sell']"
					/>
				</div>

				<div class="p-inputgroup mt-1">
					<span class="p-float-label">
						<Dropdown
							v-model="basicInfoValidation.forexContractSize.$model"
							:class="{'p-invalid':basicInfoValidation.forexContractSize.$invalid && basicInfoSubmitted, 'flex-grow-1': true}"
							:options="forexSymbolsContractSize"
							:filter="true"
							optionLabel="symbol"
							optionGroupLabel="label"
							optionGroupChildren="items"
							id="forexContractSize"
							placeholder="คู่เงิน"
							@change="updateContractSize"
						>
							<template #optiongroup="{option}">
								<div class="mt-3 text-xl">
									{{ option.label }}
								</div>
							</template>
						</Dropdown>
						<!-- <label for="forexContractSize">คู่เงิน</label> -->
					</span>

					<span class="p-float-label">
						<Dropdown
							v-model="basicInfoValidation.forexLeverage.$model"
							:class="{'p-invalid':basicInfoValidation.forexLeverage.$invalid && basicInfoSubmitted, 'flex-grow-1': true}"
							:options="calculatorConfigs?.forexLeverage || []"
							id="forexLeverage"
							placeholder="Leverage"
						>
							<template #option="{option}">
								1:{{ option }}
							</template>
							<template #value="{value}">
								1:{{ value }}
							</template>
						</Dropdown>
						<!-- <label for="forexLeverage">Leverage</label> -->
					</span>
				</div>

				<div class="p-inputgroup mt-1">
					<span class="p-inputgroup-addon">
						เงินลงทุนของคุณ
					</span>
					<InputNumber
						v-model="basicInfoValidation.portInvest.$model"
						:class="{'p-invalid':basicInfoValidation.portInvest.$invalid && basicInfoSubmitted}"
						:minFractionDigits="2"
						:maxFractionDigits="2"
						:disabled="!basicInfoState.forexContractSize"
						suffix=" $"
						currency="USD"
						locale="en-US"
					/>
				</div>

				<div class="p-inputgroup mt-1">
					<span class="p-inputgroup-addon">
						ราคาปัจจุบัน
					</span>
					<InputNumber
						v-model="basicInfoValidation.currentPrice.$model"
						:class="{'p-invalid':basicInfoValidation.currentPrice.$invalid && basicInfoSubmitted}"
						:minFractionDigits="2"
						:maxFractionDigits="5"
						:disabled="!basicInfoState.forexContractSize"
						:suffix="` ${basicInfoState.forexContractSize?.currency || '$'}`"
						currency="USD"
						locale="en-US"
					/>
				</div>

				<div class="p-inputgroup mt-1">
					<span class="p-inputgroup-addon">
						ราคาที่ต้องการ Take Profit
					</span>
					<InputNumber
						v-model="basicInfoValidation.profitPrice.$model"
						:class="{'p-invalid':basicInfoValidation.profitPrice.$invalid && basicInfoSubmitted}"
						:minFractionDigits="2"
						:maxFractionDigits="5"
						:disabled="!basicInfoState.forexContractSize"
						:suffix="` ${basicInfoState.forexContractSize?.currency || '$'}`"
						currency="USD"
						locale="en-US"
					/>
				</div>
				<div class="p-inputgroup mt-1">
					<span class="p-inputgroup-addon">
						ราคาที่ต้องการ Stop Loss
					</span>
					<InputNumber
						v-model="basicInfoValidation.lossPrice.$model"
						:class="{'p-invalid':basicInfoValidation.lossPrice.$invalid && basicInfoSubmitted}"
						currency="USD"
						:minFractionDigits="2"
						:maxFractionDigits="5"
						:disabled="!basicInfoState.forexContractSize"
						:suffix="` ${basicInfoState.forexContractSize?.currency || '$'}`"
						locale="en-US"
					/>
				</div>

				<Button
					label="คำนวณ"
					class="mt-4"
					@click="lotsCalculate"
				/>
				<Dialog
					v-model:visible="calculatedModal"
					header="ภาพรวมพอร์ตการเทรดตอนนี้"
					:breakpoints="{'960px': '75vw', '640px': '90vw'}"
					:style="{width: '50vw'}"
					:modal="true"
				>
          <Divider
						align="left"
						type="dashed"
						class="text-lg mt-5 mb-4"
					>
						<b>ภาพรวม</b>
					</Divider>
					<p>
						คุณต้องการกำไรที่ราคา
						<span class="text-cyan-500 hover:text-cyan-700 font-semibold">{{ numberFormat(basicInfoState.profitPrice) }} {{ basicInfoState.forexContractSize?.currency || '$' }}</span>
						<br>
						และยอมรับขาดทุนที่ราคา
						<span class="text-orange-500 hover:text-orange-700 font-semibold">{{ numberFormat(basicInfoState.lossPrice) }} {{ basicInfoState.forexContractSize?.currency || '$' }}</span>
						<br>
						จากออเดอร์นี้
					</p>
					<p>
						หากราคาเคลื่อนที่ {{ orderType === "Buy" ? "ขึ้น" : "ลง" }}
						<span class="text-cyan-500 hover:text-cyan-700 font-semibold">{{ numberFormat(pricePercentIncrease) }} %</span>
						คุณจะได้กำไรจาก Take Profit ทันที
						<br>
						หากราคาเคลื่อนที่ {{ orderType === "Buy" ? "ลง" : "ขึ้น" }}
						<span class="text-orange-500 hover:text-orange-700 font-semibold">{{ numberFormat(pricePercentDecrease) }} %</span>
						คุณจะขาดทุนจาก Stop Loss ทันที
					</p>

					<Divider
						align="left"
						type="dashed"
						class="text-lg mt-5 mb-4"
					>
						<b>Uhas.com วิเคราะห์ Lots ให้</b>
					</Divider>

					<div class="w-full md:w-max">
						<Card class="advice-card shadow-3 my-2">
							<template #title>
								จำนวน Lot
								<span class="text-green-500 hover:text-green-700 font-semibold">เหมาะสม</span> คือ
								<span class="text-green-500 hover:text-green-700 font-semibold">{{ numberFormat(level0) }}</span>
								Lot(s)
							</template>
							<template #content>
								<p>
									หากราคา {{ orderType === "Buy" ? "ขึ้น" : "ลง" }}ไปที่ Take Profit จะเป็นจำนวนเงิน
									<span class="text-cyan-500 hover:text-cyan-700 font-semibold">{{ numberFormat(takeProfit(level0)) }} $</span>
									<br>
									คิดเป็น
									<span class="text-cyan-500 hover:text-cyan-700 font-semibold">{{ numberFormat(takeProfit(level0) * 100 / basicInfoState.portInvest) }} %</span>
									ของพอร์ตลงทุน
									<br>
									หากราคา {{ orderType === "Buy" ? "ลง" : "ขึ้น" }}มาที่ Stop Loss จะเป็นจำนวนเงิน
									<span class="text-orange-500 hover:text-orange-700 font-semibold">{{ numberFormat(stopLoss(level0)) }} $</span>
									<br>
									คิดเป็น
									<span class="text-orange-500 hover:text-orange-700 font-semibold">{{ numberFormat(stopLoss(level0) * 100 / basicInfoState.portInvest) }} %</span>
									ของพอร์ตลงทุน
								</p>
							</template>
						</Card>

						<Card class="advice-card shadow-3 my-2">
							<template #title>
								จำนวน Lot ระดับ
								<span class="text-cyan-500 hover:text-cyan-700 font-semibold">1</span> คือ
								<span class="text-cyan-500 hover:text-cyan-700 font-semibold">{{ numberFormat(level1) }}</span>
								Lot(s)
							</template>
							<template #content>
								<p>
									หากราคา {{ orderType === "Buy" ? "ขึ้น" : "ลง" }}ไปที่ Take Profit จะเป็นจำนวนเงิน
									<span class="text-cyan-500 hover:text-cyan-700 font-semibold">{{ numberFormat(takeProfit(level1)) }} $</span>
									<br>
									คิดเป็น
									<span class="text-cyan-500 hover:text-cyan-700 font-semibold">{{ numberFormat(takeProfit(level1) * 100 / basicInfoState.portInvest) }} %</span>
									ของพอร์ตลงทุน
									<br>
									หากราคา {{ orderType === "Buy" ? "ลง" : "ขึ้น" }}มาที่ Stop Loss จะเป็นจำนวนเงิน
									<span class="text-orange-500 hover:text-orange-700 font-semibold">{{ numberFormat(stopLoss(level1)) }} $</span>
									<br>
									คิดเป็น
									<span class="text-orange-500 hover:text-orange-700 font-semibold">{{ numberFormat(stopLoss(level1) * 100 / basicInfoState.portInvest) }} %</span>
									ของพอร์ตลงทุน
								</p>
							</template>
						</Card>

						<Card class="advice-card shadow-3 my-2">
							<template #title>
								จำนวน Lot ระดับ
								<span class="text-pink-200 hover:text-pink-300 font-semibold">2</span> คือ
								<span class="text-pink-200 hover:text-pink-300 font-semibold">{{ numberFormat(level2) }}</span>
								Lot(s)
							</template>
							<template #content>
								<p>
									หากราคา {{ orderType === "Buy" ? "ขึ้น" : "ลง" }}ไปที่ Take Profit จะเป็นจำนวนเงิน
									<span class="text-cyan-500 hover:text-cyan-700 font-semibold">{{ numberFormat(takeProfit(level2)) }} $</span>
									<br>
									คิดเป็น
									<span class="text-cyan-500 hover:text-cyan-700 font-semibold">{{ numberFormat(takeProfit(level2) * 100 / basicInfoState.portInvest) }} %</span>
									ของพอร์ตลงทุน
									<br>
									หากราคา {{ orderType === "Buy" ? "ลง" : "ขึ้น" }}มาที่ Stop Loss จะเป็นจำนวนเงิน
									<span class="text-orange-500 hover:text-orange-700 font-semibold">{{ numberFormat(stopLoss(level2)) }} $</span>
									<br>
									คิดเป็น
									<span class="text-orange-500 hover:text-orange-700 font-semibold">{{ numberFormat(stopLoss(level2) * 100 / basicInfoState.portInvest) }} %</span>
									ของพอร์ตลงทุน
								</p>
							</template>
						</Card>

						<Card class="advice-card shadow-3 my-2">
							<template #title>
								จำนวน Lot ระดับ
								<span class="text-purple-500 hover:text-purple-700 font-semibold">3</span> คือ
								<span class="text-purple-500 hover:text-purple-700 font-semibold">{{ numberFormat(level3) }}</span>
								Lot(s)
							</template>
							<template #content>
								<p>
									หากราคา {{ orderType === "Buy" ? "ขึ้น" : "ลง" }}ไปที่ Take Profit จะเป็นจำนวนเงิน
									<span class="text-cyan-500 hover:text-cyan-700 font-semibold">{{ numberFormat(takeProfit(level3)) }} $</span>
									<br>
									คิดเป็น
									<span class="text-cyan-500 hover:text-cyan-700 font-semibold">{{ numberFormat(takeProfit(level3) * 100 / basicInfoState.portInvest) }} %</span>
									ของพอร์ตลงทุน
									<br>
									หากราคา {{ orderType === "Buy" ? "ลง" : "ขึ้น" }}มาที่ Stop Loss จะเป็นจำนวนเงิน
									<span class="text-orange-500 hover:text-orange-700 font-semibold">{{ numberFormat(stopLoss(level3)) }} $</span>
									<br>
									คิดเป็น
									<span class="text-orange-500 hover:text-orange-700 font-semibold">{{ numberFormat(stopLoss(level3) * 100 / basicInfoState.portInvest) }} %</span>
									ของพอร์ตลงทุน
								</p>
							</template>
						</Card>

						<Card class="advice-card shadow-3 my-2">
							<template #title>
								จำนวน Lot ระดับ
								<span class="text-pink-500 hover:text-pink-700 font-semibold">สูงสุด</span> คือ
								<span class="text-pink-500 hover:text-pink-700 font-semibold">{{ numberFormat(level4) }}</span>
								Lot(s)
								<br>
								<span class="text-base text-pink-200 hover:text-pink-300 font-semibold font-italic">
									ความเสี่ยงสูงสุด ไม่ควรเทรด Lot เกินค่านี้
								</span>
							</template>
							<template #content>
								<p>
									หากราคา {{ orderType === "Buy" ? "ขึ้น" : "ลง" }}ไปที่ Take Profit จะเป็นจำนวนเงิน
									<span class="text-cyan-500 hover:text-cyan-700 font-semibold">{{ numberFormat(takeProfit(level4)) }} $</span>
									<br>
									คิดเป็น
									<span class="text-cyan-500 hover:text-cyan-700 font-semibold">{{ numberFormat(takeProfit(level4) * 100 / basicInfoState.portInvest) }} %</span>
									ของพอร์ตลงทุน
									<br>
									หากราคา {{ orderType === "Buy" ? "ลง" : "ขึ้น" }}มาที่ Stop Loss จะเป็นจำนวนเงิน
									<span class="text-orange-500 hover:text-orange-700 font-semibold">{{ numberFormat(stopLoss(level4)) }} $</span>
									<br>
									คิดเป็น
									<span class="text-orange-500 hover:text-orange-700 font-semibold">{{ numberFormat(stopLoss(level4) * 100 / basicInfoState.portInvest) }} %</span>
									ของพอร์ตลงทุน
								</p>
							</template>
						</Card>
					</div>

					<Divider
						align="left"
						type="dashed"
						class="text-lg mt-5 mb-4"
					>
						<b>คำเตือน</b>
					</Divider>
					<p>ความเสี่ยงที่เหมาะสม ยังถือว่าอยู่ในเกณฑ์ที่สูง ดังนั้นอย่าลืมหยุดความเสียหายด้วย Stop Loss</p>
					<p v-if="notEnoughCapital">{{ notEnoughCapital }}</p>
					<p v-if="highRiskLots">
						{{ highRiskLots }}
						<br>
						เนื่องจากมีความเสี่ยงมากเกินกว่า -8% อาจส่งผลให้พอร์ตลงทุนของคุณเสียหายได้
					</p>

					<template #footer>
						<Button
							label="ตกลง"
							icon="pi pi-check"
							autofocus
							@click="calculatedModal = false"
						/>
					</template>
        </Dialog>
			</div>
		</template>
	</Card>
</template>
